



























import { Component, Prop, Vue } from 'vue-property-decorator'
import { buildFAQArray } from '@/services/functions'

@Component({
  components: {
    AppCollapse: () => import('@/components/AppCollapse.vue'),
    AppCollapseItem: () => import('@/components/AppCollapseItem.vue')
  }
})
export default class FAQ extends Vue {
  @Prop() type
  buildFAQArray = buildFAQArray
}
